import React from "react";
import styled from "styled-components";
import categories from "../data/links";

const LinksSectionTitle = styled.h2``;
const LinksSectionList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;
const LinksSectionListItem = styled.li`
  margin-bottom: 0.5rem;
  a {
    color: red;
    text-decoration: none;
  }
`;

const LinksSectionBase = styled.div``;
const LinksBoxBase = styled.div`
  display: flex;
  justify-content: center;

  > ${LinksSectionBase}:not(:first-child) {
    margin-left: 1rem;
  }
`;

const LinksSection = ({ category, links }) => {
  return (
    <LinksSectionBase>
      <LinksSectionTitle>{category}</LinksSectionTitle>
      <LinksSectionList>
        {links.map((link, index) => (
          <LinksSectionListItem key={index}>
            <a href={link.href} target="_blank">
              {link.name}
            </a>
          </LinksSectionListItem>
        ))}
      </LinksSectionList>
    </LinksSectionBase>
  );
};

export default function LinksBox() {
  return (
    <LinksBoxBase>
      {categories.map((category, index) => (
        <LinksSection
          key={index}
          category={category.category}
          links={category.links}
        />
      ))}
    </LinksBoxBase>
  );
}
