const favoritesLinks = [
  { name: "Google", href: "https://google.com" },
  { name: "HubSpot", href: "https://hubspot.com" }
];

const workLinks = [
  { name: "GitHub", href: "https://git.hubteam.com" },
  {
    name: "Clients Repo",
    href: "https://git.hubteam.com/HubSpot/sales-client-infra"
  }
];

const categories = [
  { category: "favorites", links: favoritesLinks },
  { category: "hubspot", links: workLinks }
];

export default categories;
