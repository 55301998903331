import React, { useState, useEffect } from "react";
import styled from "styled-components";

const WeatherFrame = ({ lat, lon, name, textColor, barColor }) => {
  return (
    <iframe
      id="forecast_embed"
      frameborder="0"
      height="136"
      width="100%"
      src={`//forecast.io/embed/#lat=${lat}&lon=${lon}&text-color=${textColor}&color=${barColor}&hide-header=true`}
    ></iframe>
  );
};

const WeatherBase = styled.div`
  width: 180px;
  position: relative;
  left: -14px;
`;

export default function Weather() {
  const [location, setLocation] = useState(null);
  useEffect(() => {
    navigator.geolocation.getCurrentPosition(loc => {
      setLocation(loc.coords);
    });
  }, []);

  if (!location) {
    return <WeatherBase>Loading</WeatherBase>;
  }

  return (
    <WeatherBase>
      <WeatherFrame
        lat={location.latitude}
        lon={location.longitude}
        textColor="#ffffff"
        barColor="#ffffff"
      />
    </WeatherBase>
  );
}
