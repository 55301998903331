import React, { useState, useEffect } from "react";
import styled from "styled-components";

import Clock from "./Clock";
import Weather from "./Weather";
import LinksBox from "./LinksBox";

const AppRoot = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: black;
  color: orange;
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
`;

const ContentBox = styled.div`
  padding: 1rem;
`;

const InfoBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem;
  flex-wrap: wrap;
`;

const PositionedClock = styled(Clock)`
  margin-top: 14px;
`;

export default function App() {
  useEffect(() => {
    const omniboxListener = event => {
      if (event.ctrlKey && event.keyCode === 11) {
        console.log("open");
      }
    };
    document.addEventListener("keypress", omniboxListener);
    return () => document.removeEventListener("keypress", omniboxListener);
  }, []);

  return (
    <AppRoot>
      <ContentBox>
        <InfoBox>
          <PositionedClock />
          <Weather />
        </InfoBox>
        <LinksBox />
      </ContentBox>
    </AppRoot>
  );
}
